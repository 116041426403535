<template>
  <el-container class="app-main-box">
    <el-aside class="aside">
      <Aside></Aside>
    </el-aside>
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main class="app-main-view">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { Aside, Header } from "./components";
export default {
  name: "layout",
  components: {
    Aside,
    Header,
  },
};
</script>

<style lang="less" scoped>
.app-main-box {
  height: 100%;
  width: 100%;

  .aside {
    width: 16.13% !important;
    background-color: #0052d9;
    height: 100%;
    border: none !important;
  }

  .app-main-view {
    background-color: #f2f2f2;
  }
}
</style>