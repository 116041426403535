<template>
  <div class="AsideBox">
    <div class="aside-icon"></div>
    <el-menu mode="vertical" class="menu" text-color="#ffffff" active-text-color="#FFFFFF" ref="sys_menu">
      <MenuItem v-for="(route, index) of permission_routes" :key="index" :item="route" :activeMenu="activeMenu"
         @childClick="updateHande">
      </MenuItem>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuItem from "./MenuItem.vue";
export default {
  name: "app_menu",
  components: {
    MenuItem,
  },
  data() {
    return {
      activeMenu: "",
    };
  },
  watch: {
    $route: {
      handler(to) {
        const { meta, path } = to;
        if (meta.activeMenu) {
          this.activeMenu = meta.activeMenu;
        }
        this.activeMenu = path;
        this.updateHande(this.activeMenu);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["permission_routes"]),
  },
  methods: {
    updateHande(e) {
      if (!this.$children.length) { return }
      this.$children[0].$children.forEach((item) => {
        item.init(e);
      });
      if (e.indexOf("/orderManagement") == -1) {
        this.$refs.sys_menu.close("/orderManagement/");
      } else {
        this.$refs.sys_menu.open("/orderManagement/");
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.activeMenu.indexOf("orderManagement") != -1) {
        this.$refs.sys_menu.open("/orderManagement/");
      }
    });
  },
};
</script>

<style lang="less" scoped>
.AsideBox {
  .aside-icon {
    padding-left: 100%;
    padding-top: 33%;
    box-sizing: border-box;
    background-image: url("../../../assets/System_logo.png");
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
  }

  .menu {
    background-color: transparent;
    border: none;
  }
}
</style>