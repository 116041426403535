<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
        <span class="text">{{ item }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const breadcrumbs = [
        { meta: { title: "仪器检测自动化报告系统" } },
        ...matched,
      ];
      const list = [];
      breadcrumbs.forEach((item) => {
        if (item.meta) {
          if (item.meta.fatherTitle) {
            list.push(item.meta.fatherTitle);
          }
          if (item.meta.title) {
            list.push(item.meta.title);
          }
        }
      });

      this.levelList = list;
    },
  },
};
</script>

<style lang="less" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: var(--fontSizeBig);
  line-height: 50px;
  margin-left: 8px;

  .text {
    color: #000000;
    cursor: text;
  }
}
</style>
