const getters = {
  token: function (state) {
    return state.user.token;
  },
  roles: function (state) {
    return state.user.roles;
  },
  permissions: function (state) {
    return state.user.permissions;
  },
  permission_routes: function (state) {
    return state.permission.routes;
  },
  user: function (state) {
    return state.user.user;
  }
}
export default getters
