/**
 * 
 */
function loadPageOficeJs(url = '/api/pageoffice.js') {
  const el = document.createElement('script');
  el.type = "text/javascript";
  el.src = url;
  el.onerror = (e) => {
    console.log(e);
    console.log('js加载失败');
  }
  el.onload = (e) => {
    console.log(e);
    console.log('js加载完成');
  }
  document.body.appendChild(el);
}

/**
 * 初始化执行函数
 */
export function windowInitFun() {
  /**
 * 加载PageOfice
 */
  loadPageOficeJs();
  window.PageOfficeCloseFn = function () { }
  window.PageOfficeCbFn = function () { }
  const sel = window.getSelection()
  for (let i = 0; i < sel.rangCount; i++) {
    range[i] = sel.getRangeAt(i)
  }
}
