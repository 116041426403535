<template>
  <div class="steps-com-box">
    <el-steps :active="active" align-center style="width: 100%" class="steps">
      <el-step
        v-for="(item, index) of steps"
        :key="index"
        :title="item.title"
        :description="item.description"
      >
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  name: "steps",
  props: {
    //配置项
    steps: {
      require: true,
      type: Array,
      default: [
        {
          title: "", //步骤名
          description: "", //步骤描述
        },
      ],
    },
    //当前步骤
    active: {
      require: true,
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="less" scoped>
.steps-com-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
