<template>
  <div>
    <remote-script src="/js/pdfJs/pdf.js" @load="initView"></remote-script>
    <div id="pdfShowView"></div>
  </div>
</template>

<script>
let that;
export default {
  name: "completed_order_view",
  props: {
    orderId: {
      type: String | Number
    }
  },
  methods: {
    initView: function () {
      if (!this.orderId) return;
      const baseUrl = "/api/SimpleFinalExcel/OrderPDF?orderId=";
      const pdfUrl = baseUrl + this.orderId;

      let requestUrl = {
        url: pdfUrl,
        httpHeaders: {
          Authorization: "Bearer " + this.$store.getters.token
        }
      }
      window.pdfjsLib.getDocument(requestUrl).promise.then(function (PDF) {
        let pageSize = PDF._pdfInfo.numPages;
        for (let i = 1; i <= pageSize; i++) {
          PDF.getPage(i).then(function (page) {
            that.render(page);
          });
        }
      });
    },
    render: (page) => {
      let canvas = document.createElement("canvas");
      canvas.classList.add("pdf-page");
      let outputScale = window.devicePixelRatio || 1;
      let transform =
        outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
      const viewport = page.getViewport({ scale: 1.5 });
      const context = canvas.getContext("2d");
      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.width = Math.floor(viewport.width) + "px";
      canvas.style.height = Math.floor(viewport.height) + "px";
      document.querySelector("#pdfShowView")?.appendChild(canvas);
      page.render({
        canvasContext: context,
        viewport: viewport,
        transform: transform,
      });
    }
  },
  mounted() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
#pdfShowView {
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  box-sizing: border-box;
}

.pdf-page {
  border: 2px solid #cbcbcb;
}
</style>