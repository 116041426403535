import { login, logout, getInfo } from '../../api/login'
import { getToken, setToken, removeToken } from '../../utils/auth'

export default {
  state: {
    token: getToken(),
    roles: [],
    permissions: [],
    user: {},
  },
  mutations: {
    SET_TOKEN: function (state, token) {
      state.token = token
    },
    SET_ROLES: function (state, roles) {
      state.roles = roles
    },
    SET_PERMISSIONS: function (state, permissions) {
      state.permissions = permissions
    },
    SET_USER: function (state, user) {
      state.user = user
    }
  },
  actions: {
    // 登录
    Login: function ({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise(function (resolve, reject) {
        login(username, password).then(function (res) {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(function (error) {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise(function (resolve, reject) {
        getInfo().then(function (res) {
          const user = res.user;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          }
          commit('SET_USER', user)
          resolve(res)
        }).catch(function (error) {
          reject(error)
        })
      })
    },

    // 退出系统
    Logout({ commit }) {
      return new Promise(function (resolve, reject) {
        logout().then(function () {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve()
        }).catch(function (error) {
          reject(error)
        })
      })
    },

    // 前端登出
    FedLogOut({ commit }) {
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

