<template>
  <div class="login">
    <div class="login-icon"></div>
    <div class="login-title">{{ title }}</div>
    <div class="login-form-box">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" label-position="left">
        <el-form-item prop="username" label="账号">
          <el-input v-model="loginForm.username" type="text" auto-complete="off" ref="UserNameInput">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="loginForm.password" type="password" auto-complete="off">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="login-tip">
        {{ tip }}
      </div>
      <el-button v-on:keydown.enter="loginHandler" class="login-button" @click="loginHandler">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "login_view",
  data() {
    return {
      title: "仪器检测自动化报告系统",
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
      },
      tip: "请使用检测信息管理系统平台账号登录，如您忘记密码，请至检测信息管理系统平台找回",
      redirect: undefined,
    };
  },
  watch: {
    //实现重定向到登录前界面
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 登录处理函数
     */
    loginHandler() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("Login", this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => { });
            })
            .catch((e) => {
              this.$modal.msgWarning(e.msg);
            });
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.UserNameInput.focus();
    });
  },
};
</script>

<style lang="less" scoped>
.login {
  background-image: url("../assets/bg.png");
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 16px;
  gap: 6%;
  box-sizing: border-box;
  padding-top: 5%;

  .login-icon {
    width: 300px;
    height: 100px;
    background-image: url("../assets/System_logo.png");
    background-size: 100% 100%;
  }

  .login-title {
    font-weight: normal;
    color: #ffffff;
    font-size: 72px;
    letter-spacing: 16px;
  }

  .login-form-box {
    width: 484px;
    height: 293px;
    background-image: -webkit-linear-gradient(to bottom, #57b5f977, #0014b773);
    background-image: -o-linear-gradient(to bottom, #57b5f977, #0014b773);
    background-image: -moz-linear-gradient(to bottom, #57b5f977, #0014b773);
    /*标准写法*/
    background: linear-gradient(to bottom, #57b5f977, #0014b773);
    /*ie8以上*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#57b5f977',endColorStr='#0014b773',gradientType=1)";
    /*ie9以上*/
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#57b5f977, endColorstr=#0014b773);
    // background: linear-gradient();
    border-radius: 20px;
    overflow: hidden;
    padding: 1.5% 2.5%;
    box-sizing: border-box;
    position: relative;

    .login-form {
      border-radius: 6px;
      width: 100%;
    }

    .login-button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      background: linear-gradient(to bottom, #57b5f977, #0014b773);
      border: none;
      color: #ffffff;
      letter-spacing: 10px;
      min-height: 50px;
      font-size: 24px;
      font-weight: 500;
    }

    .login-tip {
      color: #6cfeff;
      font-size: 14px;
      text-align: left;
      letter-spacing: 1px;
    }
  }
}
</style>
