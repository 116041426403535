import { constantRoutes, dymaicRouter, resetRouter } from '@/router'
import store from "@/store"
const permission = {
  state: {
    routes: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        if (store.getters.roles.indexOf("admin") !== -1) {
          resetRouter(dymaicRouter);
        }
        commit('SET_ROUTES', constantRoutes);
        resolve();
      })
    }
  }
}

export default permission
