<template>
  <div class="view-card-box">
    <div class="header">
      <div>
        {{ title }}
      </div>
      <slot name="header-left"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "view_card",
  props: {
    title: {
      default: "标题",
    },
  },
};
</script>

<style lang="less" scoped>
.view-card-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  border-radius: 10px;
  background-color: #ffffff;

  .header {
    border-bottom: 1px solid #bbbbbb;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1%;
    font-size: var(--fontSizeBig);
    color: #000000;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
  }

  .content {
    margin-top: 20px;
    flex: 1;
    width: 100%;
    padding: 0 1%;
    box-sizing: border-box;
    overflow: hidden;
  }
}
</style>