<template>
  <div class="header-box">
    <div>
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="left">
      <el-popover placement="bottom" v-model="visible" trigger="click">
        <svg-icon icon-class="person" :size="2" slot="reference"></svg-icon>
        <div class="userInfos">
          <div style="padding-bottom: 10px;border-bottom: 1px solid #cbcbcb;">
            <div class="user-name">
              <svg-icon icon-class="person" :size="2.2"></svg-icon>
              <span>{{ $store.getters.user.nickName }}</span>
              <div class="role">
                <span>{{ $store.getters?.user?.roles && $store.getters?.user?.roles?.[0].roleName || "未知" }}</span>
              </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;">
            <button @click="logout" class="logout-btn">退出登录</button>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "app_header",
  data: () => {
    return {
      visible: false
    }
  },
  components: {
    Breadcrumb,
  },
  methods: {
    logout: async function () {
      let _that = this;
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          _that.$store.dispatch("Logout").then(function () {
            location.href = "/index";
          });
        })
        .catch(function (e) {
        });
    },
  },
  created() {
    window.xxx = this
  }
};
</script>

<style lang="less" scoped>
.header-box {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;


  .left {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.userInfos {
  color: #101010;
  width: 200px;

  .user-name {
    font-size: var(--fontSizeBig);
    height: 30px;
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
  }

  .role {
    padding: 2px 2px;
    font-size: 10px;
    height: fit-content;
    display: flex;
    margin-top: 5px;
    align-items: end;
    color: #41c64b;

    span {
      border: .5px solid #41c64b;
      border-radius: 4px;
      padding: 2px;
      box-sizing: border-box;
    }
  }
}

.logout-btn {
  width: 90%;
  height: 30px;
  background-color: #ff5656;
  border: none;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  padding: 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px auto;
}
</style>
