<template>
  <div class="table-comp-box">
    <el-table :data="data" style="width: 100%" :border="false" :cell-class-name="tableCellClassName" :resizable="false"
      class="table" height="100%">
      <el-table-column type="selection" width="55" v-if="showSelection">
      </el-table-column>
      <el-table-column type="index" label="序号" align="center" header-align="center" v-if="showNo" width="80"
        :resizable="false">
      </el-table-column>
      <el-table-column v-for="(item, index) of option.columns" :key="index" :prop="item.prop" :label="item.label"
        :min-width="item.width || 80" align="center" header-align="center" :resizable="false">
      </el-table-column>
      <el-table-column label="操作" v-if="option.operate && option.operate.length > 0" align="center" header-align="center"
        :min-width="option.operateWith || 100" :resizable="false">
        <template slot-scope="scope">
          <div class="table-operate-box">
            <button v-for="(item, index) of option.operate" :key="index" :class="getClass(item, scope.row)"
              @click="handle(scope.row, item.handle)">
              {{
                typeof item.label == "function"
                ? item.label(scope.row)
                : item.label
              }}
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-bottom" v-if="showPagination">
      <div class="table-pagination">
        <el-pagination background :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
          layout="total, sizes,prev, pager, next,jumper" :total="totalNumber" @size-change="pageSizeChange"
          @current-change="pageNumChange" :current-page.sync="pageNum">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      pageSize: 10,
      pageNum: 1,
    };
  },
  props: {
    //构件表配置
    option: {
      require: true,
      type: Object,
      defult: {
        columns: [
          {
            prop: "", //string 映射数据Key
            label: "", //string 表头显示字段
            width: "", //number 列宽度
          },
        ],
        operate: [
          {
            type: "", //操作按钮显示类型
            label: "", //按钮显示文字
            handle: "", //回调函数
          },
        ],
        operateWith: 10, //操作栏宽度
      },
    },
    //是否显示序号
    showNo: {
      type: Boolean,
      default: true,
    },
    //数据表数据
    data: {
      require: true,
      defult: [],
    },
    //设置是否在右下角显示总条数
    showTotal: {
      default: false,
    },
    //设置每页显示条数
    displaynumber: {
      default: 10,
    },
    total: {
      default: "",
    },
    isShowPagination: {
      default: false,
    },
    showSelection: {
      default: false,
    }
  },
  computed: {
    showPagination() {
      return this.isShowPagination
        ? true
        : this.data.length > this.displaynumber
          ? true
          : false;
    },
    totalNumber() {
      if (this.total && this.total != "") {
        return this.total;
      }
      return this.data.length;
    },
  },
  methods: {
    //根据单元格值  为单元格设置不同的文字显示颜色
    tableCellClassName(e) {
      if (e.columnIndex == 6 && e.row.state) {
        return e.row.state;
      }
    },
    handle(e, cb) {
      cb(e);
    },
    getClass(e, scope) {
      let _class = [];
      if (typeof e.class == "function") {
        return e.class(scope);
      }
      if (e.class) _class.push(e.class);
      return _class;
    },
    pageSizeChange(e) {
      this.pageSize = e;
      this.change();
    },
    pageNumChange() {
      this.change();
    },
    change() {
      this.$emit("Change", {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
    },
  },
};
</script>

<style lang="less">
.table-comp-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  .table-bottom {
    display: flex;
    justify-content: right;
    // margin-top: 1%;
    // padding: 0 1%;
    box-sizing: border-box;
    height: 80px;
    padding: 10px 0;

    .total-view {
      span {
        font-size: 16px;
        color: #000000;
      }
    }
  }
}

.el-table {
  font-size: 16px;
  width: 100%;
  height: 100%;
  flex: 1;
  margin-bottom: 2px;
  border: none;
  border-top: 1px solid #ebeef5;

  tbody {
    font-size: 17px;
  }

  .el-table__cell {
    border-right: 1px solid #ebeef5;
  }

  .el-table__row {
    .el-table__cell:first-child {
      border-left: 1px solid #ebeef5;
    }
  }
}

.el-table::before {
  display: none;
}

.el-table__header-wrapper {
  .has-gutter {
    .el-table__cell:first-child {
      border-left: 1px solid #ebeef5;
    }
  }
}
</style>
