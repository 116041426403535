import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
Vue.use(Router);

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * meta : {
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 路由
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/view/login"),
    name: "login",
    hidden: true, //菜单列表不显示该条
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    redirect: "index",
    component: Layout,
    alwaysShow: true,
    isLayout: true, //当前不显示路由
    children: [
      {
        path: "index",
        component: () => import("@/view/home/index"),
        name: "index",
        meta: {
          title: "首页",
          icon: "home",
          activeMenu: "/index",
        },
      },
    ],
  },
  {
    path: "/orderManagement/",
    component: Layout,
    name: "orderManagement",
    redirect: "/orderManagement/waitSampleOrder",
    alwaysShow: true,
    isLayout: true, //当前不显示路由
    isNest: true,
    meta: {
      title: "工单管理",
      icon: "ordered-list",
      activeMenu: "/orderManagement/",
    },
    children: [
      {
        path: "waitSampleOrder",
        name: "waitSampleOrder",
        component: () => import("@/view/waitSampleOrder"),
        meta: {
          title: "待领样工单",
          activeMenu: "/orderManagement/waitSampleOrder",
        },
      },
      {
        path: "peddingOrder",
        name: "peddingOrder",
        component: () => import("@/view/peddingOrder"),
        name: "orderPendding",
        meta: {
          title: "检测中工单",
          activeMenu: "/orderManagement/peddingOrder",
        },
      },
      {
        path: "completedOrder",
        name: "completedOrder",
        component: () => import("@/view/completedOrder"),
        name: "orderCompleted",
        meta: {
          title: "已完成拟稿",
          activeMenu: "/orderManagement/completedOrder",
        },
      },
      {
        path: "peddingOrder/orderAcceptance",
        hidden: true,
        name: "orderAcceptance",
        component: () => import("@/view/orderAcceptance/index"),
        meta: {
          title: "受理",
          fatherTitle: "新增工单", //兼容面包屑显示
        },
      },
      {
        path: "completedOrder/view",
        hidden: true,
        name: "completedOrderView",
        component: () => import("@/view/completedOrder/viewOrder"),
        meta: {
          title: "查看报告",
          fatherTitle: "已完成工单", //兼容面包屑显示
        },
      },
    ],
  },
  {
    path: "/recordManagement",
    component: Layout,
    alwaysShow: true,
    isLayout: true, //当前不显示路由
    children: [
      {
        path: "/",
        component: () => import("@/view/recordManagement"),
        name: "recordManagement",
        meta: {
          title: "记录本模板管理",
          icon: "file",
          activeMenu: "/recordManagement/",
        },
      },
    ],
  },
  {
    path: "/reportManagement",
    component: Layout,
    alwaysShow: true,
    isLayout: true, //当前不显示路由
    children: [
      {
        path: "/",
        component: () => import("@/view/reportManagement"),
        name: "reportManagement",
        meta: {
          title: "报告模板管理",
          icon: "records",
          activeMenu: "/reportManagement/",
        },
      },
      {
        path: "create",
        hidden: true,
        name: "createReport",
        component: () => import("@/view/reportManagement/create"),
        meta: {
          title: "创建报告模板",
          fatherTitle: "报告模板管理", //兼容面包屑显示
        },
      },
    ],
  },
  {
    path: "/PgOffice/CreateExcel",
    name: "pg_create_excel",
    component: () => import("@/pageOffice/Excel/CreateExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/viewOldData",
    name: "pg_viewOldData",
    component: () => import("@/pageOffice/Excel/viewOldDataExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/reportCreateExcel",
    name: "reportCreateExcel",
    component: () => import("@/pageOffice/Excel/reportCreateExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/viewExcel",
    name: "pg_view_excel",
    component: () => import("@/pageOffice/Excel/ViewExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/reportEditor",
    name: "reportEditor",
    component: () => import("@/pageOffice/Excel/reportEditor.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/SimpleExcelreadOnly",
    name: "SimpleExcelreadOnly",
    component: () => import("@/pageOffice/Excel/SimpleExcelreadOnly.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/SimpleFinalExcelreadOnly",
    name: "SimpleFinalExcelreadOnly",
    component: () => import("@/pageOffice/Excel/SimpleFinalExcelreadOnly.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/orderExcel",
    name: "orderExcel",
    component: () => import("@/pageOffice/Excel/orderExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/editorExcel",
    name: "editorExcel",
    component: () => import("@/pageOffice/Excel/editorExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/continueEditorOrderExcel",
    name: "continueEditorOrderExcel",
    component: () => import("@/pageOffice/Excel/continueEditorOrderExcel.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/DataRegionDlg",
    name: "DataRegionDlg",
    component: () => import("@/pageOffice/DataRegionDlg/DataRegionDlg.vue"),
    hidden: true,
  },
  {
    path: "/PgOffice/simpleWord",
    name: "simpleWord",
    component: () => import("@/pageOffice/word/simpleWord.vue"),
    hidden: true,
  },
  //未命中路由重定向到首页
  {
    path: "*",
    redirect: "/",
    hidden: true,
  },
];

export const dymaicRouter = [
  {
    path: "/dataManage",
    component: Layout,
    alwaysShow: true,
    isLayout: true, //当前不显示路由
    children: [
      {
        path: "/",
        component: () => import("@/view/dataManage"),
        name: "dataManage",
        meta: {
          title: "数据传导",
          icon: "transmission",
          activeMenu: "/dataManage/",
        },
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    routes: constantRoutes,
  });

const router = createRouter();

export const resetRouter = (routes) => {
  routes.forEach((route) => {
    constantRoutes.push(route);
  });
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
};

export default router;
