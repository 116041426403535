import "babel-polyfill";

import Vue from 'vue';
//elementui
import VueJsx from 'vue-jsx';
import ElementUI from 'element-ui';
import { windowInitFun } from '@/plugins/initFun';
import { initCustomDirectives } from "@/plugins/customDirective";
import 'element-ui/lib/theme-chalk/index.css';
import { initCustomComp } from "@/plugins/customComponent"

//Vuex
import store from './store'
//Vue-router
import router from './router'
//路由守卫,权限校验
import './permission' // permission control
import plugins from './plugins' // plugins
import App from './App.vue'
//引入自定义图标
// import "./icons"
// //引入全局css
import "./style.less"
Vue.use(ElementUI);
Vue.use(plugins);
Vue.use(VueJsx);


//全局组件挂载
initCustomComp(Vue);
Vue.config.productionTip = false

/**
 * 初始化函数执行
 */
windowInitFun();
// 注册自定义指令
initCustomDirectives();

const req = require.context('./icons/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req);

new Vue({
  store,
  router,
  render: h => h(App),
  strict: false
}).$mount('#app')
