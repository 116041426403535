<template>
  <div v-if="!item.hidden" class="menu-item-box" :class="{ 'active-menu': isActive }">
    <el-menu-item v-if="!isNest" :index="(routeItem && routeItem.name) || undefined" class="menu-item"
      @click="routeHandle(routeItem)">
      <span>{{ routeItem && routeItem.meta && routeItem.meta.title }}</span>
    </el-menu-item>
    <el-submenu :index="routeItem.path" v-else class="menu-item">
      <template slot="title" class="menu-item-title">
        <span> {{ routeItem.meta && routeItem.meta.title }}</span>
      </template>
      <el-menu-item v-for="(obj, index) of routeItem.children" :key="obj.path + index" :index="obj.path" class="menu-item"
        @click="routeHandle(obj)" v-if="!obj.hidden"
        :class="{ isSelectd: obj.meta && obj.meta.activeMenu == activePath || activeMenu.indexOf(obj.meta.activeMenu) != -1 }">
        <span>{{ obj.meta && obj.meta.title }}</span>
      </el-menu-item>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: "menu_item",
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    activeMenu: {
      type: String,
    },
  },
  computed: {
    basePath() {
      return this.item.path;
    },
  },
  data() {
    return {
      routeItem: null,
      isNest: false,
      isActive: true,
      activePath: "",
      map: null
    };
  },
  methods: {
    formatItem(routeItem) {
      if (routeItem.isNest) {
        this.routeItem = routeItem;
        this.isNest = true;
      } else {
        this.routeItem =
          routeItem.children &&
          routeItem.children.length != 0 &&
          routeItem.children[0];
        this.isNest = false;
      }
    },
    routeHandle(obj) {
      this.$router.push({ path: this.basePath + obj.path }).catch(() => { });
      this.$emit(
        "childClick",
        (obj && obj.meta && obj.meta.activeMenu) || obj.path
      );
    },
    init(e) {
      this.activePath = e;
      if (this.routeItem && this.routeItem.meta) {
        this.isActive =
          this.routeItem.meta.activeMenu == e ||
          e.indexOf(this.routeItem.meta.activeMenu) != -1;
      }
      this.$emit("update", e);
    },
  },
  mounted() {
    this.formatItem(this.item);
    this.init(this.activeMenu);
  },
};
</script>

<style lang="less">
.menu-item-box {
  margin-left: 20px;
  border-radius: 18px 0 0 18px;
}

.menu-item {
  text-align: left;
  font-size: var(--fontSizeBig);
  letter-spacing: 2px;
}

.el-submenu,
.el-submenu__title {
  text-align: left;
  font-size: var(--fontSizeBig);
  letter-spacing: 2px;
}

.el-menu {
  background-color: transparent !important;
  padding-bottom: 10px;
}

.el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover {
  outline: 0;
  background-color: var(--blue);
}

.active-menu {
  @width: 20px;
  position: relative;
  background-color: #f2f2f2 !important;

  &::after,
  &::before {
    content: "";
    height: @width;
    width: @width;
    position: absolute;
    content: "";
    background-image: radial-gradient(circle at 0 0,
        transparent @width - 1px,
        #f2f2f2 @width );
    transform: rotate(0deg);
  }

  &::before {
    right: 0;
    bottom: -@width;
    transform: rotate(270deg);
  }

  &::after {
    right: 0;
    top: -@width;
  }

  .el-menu-item {
    color: var(--blue) !important;
    font-weight: 600;
    background-color: transparent;
  }

  .el-submenu {
    overflow: hidden;
    background-color: #f2f2f2;
    border-radius: 20px;

    .el-menu-item {
      font-weight: unset;
    }

    .el-submenu__title {
      color: var(--blue) !important;
      height: 70px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .isSelectd {
      background-color: #3c46f410;
      border-radius: 0 20px 20px 0;
      font-weight: 600;
    }
  }

  .menu-item:not(.el-submenu) {
    height: 70px;
    display: flex;
    align-items: center;
  }
}

.active_up {
  width: 100%;
  height: 20px;
  background: var(--blue);
  border-radius: 0 0 20px 0;
}

.activew_down {
  width: 100%;
  height: 20px;
  background: var(--blue);
  border-radius: 0 20px 0 0;
}
</style>
